import { useEffect, useState } from "react";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import { FaCalendarAlt, FaTruck, FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SelectAlmacenes from "../../../components/Almacenes";
import SelectCentro from "../../../components/Centros";
import LocalMsgModalSpinner from "../../../components/LocalMsgModalSpinner";
import * as env from '../../../env';
import { httpApiGetText, httpApiPostText } from "../../../lib";
import DataTable from "react-data-table-component";
import { exportToExcel, formatDate, formatTime } from "../../../util";
import MsgDialog from "../../../components/MsgDialog";
import BarraMenuHz from "../../../components/BarraMenoHz";
import { SetEntornoEmp } from "../../../redux/store/Actions";
import * as XLSX from "xlsx";
  
let nr = 0;  
let aarrSi:any = [];

const SolicitudMermasPage = () =>{

    let emp: any = useSelector((state: any) => state.emp);  
    const [hbltBtnAbrir, setHbltBtnAbrir] = useState(true); 
    let [formData, setFormData] = useState({ Pais: "CL", Anno: "", Mes: "",  Centro: "", Almacen: "", Centro_Costo: ""});
    const [sHCarga, setSHCarga] = useState(false);
    const [negocio, setNegocio] = useState(new Array());    
    let [familia, setFamilia] = useState(new Array());        
    let [modelo, setModelo] = useState(new Array());   
    let [records, setRecords] = useState(new Array<any>());  
    const [cpRecords, setCpRecords] = useState(new Array<any>());    
    const [filtrobusqueda, setFiltroBusqueda] = useState(new Array<any>());     
    let [formSelect, setFormSelect] = useState({ Negocio: "TODOS", Modelo: "TODOS", Familia: "TODOS"}); 
    const [showCargaMasiva, setShowCargaMasiva] = useState(false); 
    const [showMsgApiResponse, setShowMsgApiResponse] = useState(false); 
    const [MsgApiResponse, setMsgApiResponse] = useState(""); 
    const [showAdvertencia, setShowAdvertencia] = useState(false);        
    const [msgAdvertencia, setMsgAdvertencia] = useState("");       
    let [chks, setChks] = useState([]);   
    let [rowsPreselected, setRowsPreselected] = useState(new Array<any>());   
    const [clrRows, setCLRows] = useState(false);  
    const dispatch = useDispatch();    
    let [fileName, setFilename] = useState<any>();     
    let [codigos, setCodigos] = useState<any>([]);         
         

    let columns = [
        { name: "Código", selector: (row:any) => row.Codigo, sortable: true},
        { name: "Especial", selector: (row:any) => row.Especial, sortable: true},
        { name: "Descripción", selector: (row:any) => row.Descripcion, sortable: true, width:"250px", wrap:true},
        { name: "UM", selector: (row:any) => row.Unidad_medida, sortable: true},
        { name: "Saldo SAP", selector: (row:any) => row.Saldo_sap,  format: (row:any) => row.Saldo_sap.toLocaleString(), right:true, sortable: true, width:"150px"},            
        { name: "Saldo Ctrl. Cal.", selector: (row:any) => row.Control_cal_sap,   format: (row:any) => row.Control_cal_sap.toLocaleString(), sortable: true, right:true, width:"150px"},
        { name: "Bloqueado", selector: (row:any) => row.Bloqueado, format: (row:any) => row.Bloqueado.toLocaleString(), sortable: true, width:"150px", right:true},
        { name: "Total Sap", selector: (row:any) => row.Total_sap, format: (row:any) => row.Total_sap.toLocaleString(), right:true, sortable: true, width:"150px"},           
        { name: "Negocio", selector: (row:any) => row.Negocio,  sortable: true, width:"150px"},
        { name: "Familia", selector: (row:any) => row.Familia, sortable: true, width:"150px"},
        { name: "Modelo", selector: (row:any) => row.Modelo, sortable: true, width:"150px"},  
        { name: "Det. Especial", selector: (row:any) => row.Det_Especial,  omit: true, sortable: true, width:"150px"},                           
    ]

    const pagOptions = {
        rowsPerPageText: "Filas por páginas",
        rangeSeparatorText: "de",
        selectAllRowsItem: true,
        selectAllRowsItemText: "Todos"
    };  

    const concatDistinct = (arrIni: any, arrFin: any) =>{
            
        const arrRes = [...arrIni];

        arrFin.map((dt: any)=>{
            if (arrRes.findIndex((valor:any) => valor === dt) === -1){
                arrRes.push(dt);
            }
        });

        return (arrRes);
    }

    const ValidaBtnAbrir = () => {     
        if ((formData.Anno !== "") &&
            (formData.Centro !== "") && 
            (formData.Almacen !== "") &&             
            (formData.Centro_Costo !== "")){     
                setHbltBtnAbrir(false); 
        }else{
            setHbltBtnAbrir(true); 
        }
    }

    const hndlrPeriodo = async (evnt: any) =>{
        const f: Date = evnt.target.value; 
        const yy = f.toString().split('-')[0];
        const mm = f.toString().split('-')[1];

        emp = {...emp, periodoDefault: `${yy}-${mm}`};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));   
                
        formData = {...formData, Anno: yy, Mes: mm};
        setFormData(formData);
        ValidaBtnAbrir();                    
    }

    const consultaMermas = async () =>{
        const recurso = `${env.REACT_APP_API_URL}/api/Mermas?Pais=CL&Centro=${formData.Centro}&Almacen=${formData.Almacen}`;     
        const response = await httpApiGetText(recurso,{
            'Content-Type': 'application/json',
            'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
            'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
        });
        setSHCarga(false);
        const arrDta = await JSON.parse(response.message); 
        if(response.code >= 400){
            console.log("Error Mermas CCosto - 400:", response, recurso);
        }else{
            console.log("Select almacen: ", arrDta);  
            if (arrDta.length > 0){
                formData = {
                    ...formData, 
                    Centro_Costo: arrDta[0].CENTRO_COSTO
                };             
            }else{
                formData = {
                    ...formData, 
                    Centro_Costo: ""
                };                
            }      
            setFormData({...formData});                  
        }        
    }

    const hndlrOpcionCentro = (cnt: string, nal: any) =>{
        
        if (nal.length === 1){
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: nal[0].split("-")[1].trim()
            };
            emp = {...emp, centroDefault: cnt, almacenDefault: nal[0].split("-")[1].trim()}; 
            
            consultaMermas();
        }else{
            formData = {
                ...formData, 
                Centro: cnt, 
                Almacen: ""
            }; 
            emp = {...emp, centroDefault: cnt, almacenDefault: ""};              
        }

        setFormData(formData);   
        dispatch(SetEntornoEmp({...emp})); 
        sessionStorage.setItem("entorno", JSON.stringify(emp));            
    }

    const hndlrOpcionAlmacen = async (evnt: any) =>{
        formData = {...formData, [evnt.target.id]: evnt.target.value.split("-")[0].trim()};
        setFormData(formData);     
        
        emp = {...emp, almacenDefault: evnt.target.value};
        dispatch(SetEntornoEmp({...emp}));  

        sessionStorage.setItem("entorno", JSON.stringify(emp));  
       
        consultaMermas();
    }   
    
    const btnBuscar = async () =>{
        
        if (fileName){
            console.log("Validar archivo excel");
            //setSHCarga(true);
            const bdy = {
                Pais: "CL",
                Anno: formData.Anno,
                Mes: formData.Mes,
                Centro: formData.Centro,
                Almacen: formData.Almacen,
                CENTRO_COSTO : formData.Centro_Costo,
                FECHA_CREACION : `${formatDate(new Date())}T${formatTime(new Date())}`,
                USU_CREACION: emp.user,
                ESTADO_MERMA : 1,
                Detalle: [...codigos]
            };
            console.log("bdy : ", bdy);               
            const recurso = `${env.REACT_APP_API_URL}/api/Mermas`;     
            const response = await httpApiPostText(recurso,"POST", {
                'Content-Type': 'application/json',
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
            }, bdy);
            const arrDta = await JSON.parse(response.message);    
            if(response.code >= 400){
                console.log("Error Mermas:", response, recurso);
            }else{     
                console.log("Data Response: ", arrDta);
            }                    
        }else{
            console.log("No ha seleccionado archivo excel");
        }
    }

    const FiltrarTabla = (slt: any) =>{        
        let arr = []; 
        if (slt.id === "Negocio"){
            if (slt.value === "TODOS"){
                arr = [...cpRecords]; 
            }else{
                arr = cpRecords.filter((obj: any)=> obj.Negocio === slt.value);                
            }
            // Selecciona las opciones que deben aparecer en el selector de familia que solo estén en el filtro de negocios
            familia =[];
            arr.map((obj: any)=>{
                if (familia.findIndex((dato: string)=> dato === obj.Familia) === -1){
                    familia.push(obj.Familia.trim());
                } 
            });
            familia.sort();
            familia.unshift("TODOS");
            setFamilia([...familia]);                        
        }else if (slt.id === "Familia"){
            if (slt.value === "TODOS"){
                if (formSelect.Negocio === "TODOS"){
                    arr = [...cpRecords]; 
                }else{
                    arr = cpRecords.filter((obj: any)=> obj.Negocio === formSelect.Negocio);                   
                }
            }else{
                if (formSelect.Negocio === "TODOS"){
                    arr = cpRecords.filter((obj: any)=> (obj.Familia === slt.value));
                }else{
                    arr = cpRecords.filter((obj: any)=> (obj.Negocio === formSelect.Negocio) && (obj.Familia === slt.value));                   
                }
            } 
            // Selecciona las opciones que deben aparecer en el selector de modelo que sólo estén en el filtro de familia            
            modelo = [];
            arr.map((obj: any)=>{
                if (modelo.findIndex((dato: string)=> dato === obj.Modelo) === -1){
                    modelo.push(obj.Modelo.trim());
                } 
            });
            modelo.sort();
            modelo.unshift("TODOS");
            setModelo([...modelo]);           
        }else{
            if((formSelect.Negocio === "TODOS") && (formSelect.Familia === "TODOS")){
                arr = [...cpRecords];
            }else if ((formSelect.Negocio !== "TODOS") && (formSelect.Familia === "TODOS")){
                arr = cpRecords.filter((obj: any)=> ((obj.Negocio === formSelect.Negocio)));
            }else if ((formSelect.Negocio === "TODOS") && (formSelect.Familia !== "TODOS")){
                arr = cpRecords.filter((obj: any)=> ((obj.Familia === formSelect.Familia)));               
            }else if ((formSelect.Negocio !== "TODOS") && (formSelect.Familia !== "TODOS")){
                arr = cpRecords.filter((obj: any)=> ((obj.Negocio === formSelect.Negocio) && (obj.Familia === formSelect.Familia)));         
            }

            if (slt.value !== "TODOS"){
                arr = arr.filter((obj: any)=> ( (obj.Modelo === slt.value)) );
            }
        }
        setRecords([...arr]);    
        setFiltroBusqueda([...arr]);
    }    

    const handlerSelect = (evnt: any) =>{
        if (evnt.target.id === "Negocio"){
            formSelect = {Negocio: evnt.target.value.trim(), Modelo: "TODOS", Familia: "TODOS"};            
        }else if (evnt.target.id === "Familia"){
            formSelect = {...formSelect, Familia: evnt.target.value.trim(), Modelo: "TODOS"};     
        }else{
            formSelect = {...formSelect, Modelo: evnt.target.value.trim()};                
        }
        setFormSelect({...formSelect}); 
        FiltrarTabla(evnt.target);   
        preselectedCodeSi(aarrSi);
    }

    const changeTextFiltro = (evnt: any) => {
        records = [...filtrobusqueda];
        let reg = records.filter((dato: any)=> {
            return (dato.Codigo && dato.Codigo.toString().includes(evnt.target.value.toUpperCase()))
                || (dato.Especial && dato.Especial.includes(evnt.target.value.toUpperCase())) 
                || (dato.Descripcion && dato.Descripcion.includes(evnt.target.value.toUpperCase()))  
                || (dato.Unidad_medida && dato.Unidad_medida.includes(evnt.target.value.toUpperCase())) 
                || (dato.Saldo_sap && dato.Saldo_sap.toString().includes(evnt.target.value.toUpperCase()))                     
                || (dato.Control_cal_sap && dato.Control_cal_sap.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Bloqueado && dato.Bloqueado.toString().includes(evnt.target.value.toUpperCase())) 
                || (dato.Total_sap && dato.Total_sap.toString().includes(evnt.target.value.toUpperCase()))
                || (dato.Negocio && dato.Negocio.includes(evnt.target.value.toUpperCase()))
                || (dato.Familia && dato.Familia.includes(evnt.target.value.toUpperCase()))
                || (dato.Modelo && dato.Modelo.includes(evnt.target.value.toUpperCase()));                
        });
        setRecords([...reg]); 
    }  
    
    const preselectedCodeSi = (code: any) =>{

        setRowsPreselected(code);  
    }   

    const onRowSelected = (arr: any) =>{

        const auxarr: any = [];
        arr.selectedRows.map((dt: any)=>{
            auxarr.push(dt.Codigo);
        });
        aarrSi = concatDistinct(aarrSi, auxarr);      

        if ((arr.allSelected)){
            if (rowsPreselected.length > 0){
                preselectedCodeSi(aarrSi);
                rowsPreselected = [];
            }
        }else{
            if ((arr.selectedCount === 0) && (formSelect.Negocio === "TODOS")){
                aarrSi = [];
                preselectedCodeSi([]);
            }
        } 
        setChks(aarrSi);
    }

    const criteriPreselectable = (rws: any) => {
        nr++;
        if (rowsPreselected.length > 0){ 
            let stdo = (rowsPreselected.indexOf(rws.Codigo) !== -1);
            if (nr >= records.length){
                nr = 0;
                setRowsPreselected([]);
            }                      
            return stdo;
        }else{
            return (chks.filter((obj: any)=>obj.Codigo === rws.Codigo).length > 0);
        }
    }  

    const readExcel = (file: any) => {

        const promise = new Promise((resolve, reject) => {
            
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e: any) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: "buffer" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            };

            fileReader.onerror = (error) => {
            reject(error);
            };

        });

        promise.then((d: any) => {
            // Verifica si el archivo tiene datos
            // osea, contiene valores en los encabezados SKU y CANTIDAD
            if (d.length <= 0){
                setHbltBtnAbrir(true);                
            }else{
                // verifica si dentro de los datos, hay encabezados llamados SKU y CANTIDAD en mayúsculas
                if (d[0].SKU && d[0].CANTIDAD){
                    const arrDetail: any = [];
                    d.map((datoFind: any)=>{   
                        const skuDet = {
                            Pais: "CL",
                            Anno: formData.Anno,
                            Mes: formData.Mes,
                            Centro: formData.Centro,
                            Almacen: formData.Almacen,
                            Inv_item_id: datoFind.SKU,
                            CANTIDAD: datoFind.CANTIDAD,
                            FECHA :`${formatDate(new Date())}T${formatTime(new Date())}`,
                            USUARIO: emp.user
                        };
                        arrDetail.push(skuDet);
                    }); 
                    setCodigos([...arrDetail]); 
                    setHbltBtnAbrir(false);
                }else{
                    setHbltBtnAbrir(true);
                }
            }
        });

        return false;
    };

    useEffect(()=>{

        const preFiltros = () =>{
            if ((emp.periodoDefault !== "")){
                formData = { ...formData, Anno: emp.periodoDefault.split("-")[0],  Mes: emp.periodoDefault.split("-")[1]};
                setFormData(formData);                                         
            }  
            if ((emp.centroDefault !== "")){
                formData = { ...formData, Centro: emp.centroDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }    
            if ((emp.almacenDefault !== "")){
                formData = { ...formData, Almacen: emp.almacenDefault.split("-")[0].trim()};
                setFormData(formData);                                        
            }  

            ValidaBtnAbrir(); 
        }

        preFiltros();   
        //console.log("Emp: ", emp);         

    }, []);    
     
    return(
        <Container fluid>
            <BarraMenuHz/>  
            <div >
                <div className="text-primary ms-3 mt-3">
                        Home / Mermas / Solicitud de Mermas
                </div>
                <div className="h4 mt-4 mb-4 ms-3">SOLICITUD DE MERMAS</div>
            </div> 
            <Container fluid>  

                <Accordion  className="mb-4" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">PARÁMETROS MERMAS</div></Accordion.Header>
                        <Accordion.Body>
                            <label className="h6 mb-3">Recuerda completar todos los campos con *</label> 
                            <div className="align-middle">
                                <FaCalendarAlt className="h5" /> 
                                <label className="ms-2 h4 ">Periodo</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" />

                            <div  className="d-flex flex-column col-6">
                                <label className="m-2 ">Mes/Año</label>
                                <input type="month" id="periodo" size={8}  className="ms-2  form-control"
                                    value={`${formData.Anno}-${formData.Mes}`}
                                    onChange={hndlrPeriodo} 
                                    min="1990-01" max="9999-12"
                                />
                            </div>
                            <div className="align-middle mt-4">
                                <FaTruck className="h5" /> 
                                <label className="ms-2 h4 ">Centro y Almacen</label>
                            </div>
                            <hr className="t-0 m-0 mb-3" /> 

                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Centro de distribución</label>    
                                    <div><SelectCentro id="Centro" OnSeleccion={hndlrOpcionCentro}  default={emp.centroDefault} /></div>
                                </Container>
                                <Container fluid className="mb-3  col-md-12 col-lg-6" >
                                    <label className="form-label">Seleccionar Almacen </label>    
                                    <div ><SelectAlmacenes  id="Almacen" centro={formData.Centro}  OnSeleccion={hndlrOpcionAlmacen}   default={emp.almacenDefault} /></div>
                                </Container>                               
                            </div>
                            <div className="row  mb-3">
                                <div className=" col-md-12 col-lg-6" >
                                    <label className="form-label">Centro de costo</label>    
                                    <div>                                
                                        <input type="text" className="ms-1 form-control" value={`${formData.Centro_Costo}`} disabled />
                                    </div>
                                </div>  
                                <div className=" col-md-12 col-lg-6" >
                                    <label className="form-label">Carga archivo (.xls)</label>    
                                    <div>                                
                                        <input type="file" className="ms-1 form-control" 
                                            disabled = {(formData.Anno === "") || (formData.Centro === "") || (formData.Almacen === "") || (formData.Centro_Costo === "")}
                                            onChange={(e: any) => {
                                                fileName = e.target.files[0];
                                                if (fileName){
                                                    setFilename(fileName)
                                                    readExcel(fileName);                                                    
                                                }else{
                                                    setFilename({});
                                                    setHbltBtnAbrir(true);
                                                }
                                            }} accept="application/xlsx, .xlsx" />
                                    </div>
                                </div>                                                                
                            </div>                            

                            <div className="row ">
                                <div className=" col-md-12 col-lg-12 text-end">
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" btn border border-0" 
                                        disabled={hbltBtnAbrir} onClick={() => btnBuscar()}
                                    >
                                        Crear Solicitud
                                    </Button>
                                </div>                                
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Accordion className="mt-1" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0" className="border shadow ">
                        <Accordion.Header><div className=" h5 text-dark">MERMAS</div></Accordion.Header>
                        <Accordion.Body>
                            <div className="row d-flex flex-row mb-3">
                                <Container fluid className="mb-3 col-md-12 col-lg-4" >
                                    <label className="form-label">Seleccionar Negocio</label>    
                                    <div>
                                        <Form.Select  value={formSelect.Negocio} aria-label="Default select example" onChange={(e) => handlerSelect(e)}  id="Negocio">
                                            {
                                                negocio.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                            }                                          
                                        </Form.Select>
                                    </div>
                                </Container>

                                <Container fluid className="mb-3  col-md-12 col-lg-4" >
                                    <label className="form-label">Seleccionar Familia </label>    
                                    <div>
                                        <Form.Select  value={formSelect.Familia} aria-label="Default select example" onChange={(e) => handlerSelect(e)}  id="Familia">
                                            {
                                                familia.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                            }
                                        </Form.Select>
                                    </div>
                                </Container>

                                <Container fluid className="mb-3  col-md-12 col-lg-4" >
                                    <label className="form-label">Seleccionar Modelo</label>    
                                    <div>
                                        <Form.Select  value={formSelect.Modelo} aria-label="Default select example" onChange={(e) => handlerSelect(e)}  id="Modelo">
                                            {
                                                modelo.map((opcion: string, index: number)=><option key={index} value={opcion}>{opcion}</option>)
                                            }
                                        </Form.Select>
                                    </div>
                                </Container> 
                            </div> 
                            <div  className="text-start mb-2">
                                <Button type="button"  className="btn-lg me-2 border-0" style={{backgroundColor:"#16D39A"}}              
                                >
                                    Abrir
                                </Button>                                
                                <Button type="button" className="ms-1 border-0" style={{backgroundColor:"#2DCEE3"}}
                                    disabled={(records.length <= 0)}
                                    onClick={()=> {setShowCargaMasiva(true)}}     
                                >
                                    <FaUpload className=" m-2"/>Carga masiva
                                </Button>
                            </div>                             
                            <div className="row d-flex flex-row align-middle mt-2 mb-2">
                                <div  className="col-md-12 col-lg-6  d-flex align-items-center" >
                                    <Button type="button" style={{backgroundColor:"#00B5B8"}} className=" border-0"
                                        disabled={(records.length <= 0)}
                                        onClick={()=> {exportToExcel("TablaMuestra", records)}}
                                    >
                                        Exportar tabla a Excel
                                    </Button>
                                </div>
                                <div className="d-flex flex-column col col-lg-6 col-md-12 mb-3 ">
                                    <label className="m-1">Filtro</label>
                                    <input type="text" id="txtBuscar" className="form-control m-1" onChange={changeTextFiltro}/>
                                </div>                        
                            </div> 
                            <div className="">
                                <DataTable 
                                    columns={columns}
                                    data={records}
                                    selectableRows    
                                    selectableRowSelected={(rowsPreselected.length > 0) ? criteriPreselectable: null}       
                                    selectableRowsHighlight
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                    fixedHeaderScrollHeight="600px"
                                    paginationComponentOptions={pagOptions}
                                    onSelectedRowsChange={onRowSelected}
                                    clearSelectedRows={clrRows}
                                />                                
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> 

            </Container>
            <MsgDialog 
                Title="Período de Muestras"
                Icon="!"
                Show={showMsgApiResponse}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={MsgApiResponse}
                HandlerClickOk={()=>setShowMsgApiResponse(false)}
                HanlerdClickNok={null}
            /> 
            <MsgDialog 
                Title="Creación de Mermas"
                Icon="!"
                Show={showAdvertencia}
                BtnNokName=""
                BtnOkName="Cerrar"
                Message={msgAdvertencia}
                HandlerClickOk={()=>setShowAdvertencia(false)}
                HanlerdClickNok={null}
            /> 
                         
            {/******* Carga masiva *******************************/}          
            <LocalMsgModalSpinner 
                Show={sHCarga}
                text="Un momento por favor, procesando..."
                color="#FF7588"
            />             
        </Container>    
    );
}

export default SolicitudMermasPage;