import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alert, Button, Col, Container, Form } from 'react-bootstrap';
import { FaDoorOpen, FaKey, FaRegBuilding, FaRegHandPaper, FaUserTie } from 'react-icons/fa';
import MsgDialog from '../../components/MsgDialog';
import MsgModalSpinner from '../../components/MsgModalSpinner';
import * as env from '../../env';
import { getCentros,  getDominios, httpApiGetText} from '../../lib';
import './style.css';
import { SetEntornoEmp } from '../../redux/store/Actions';
import { ConsultaPermisos, fncDominios} from '../../util';
import { ReactSVG } from "react-svg";

const PageLogin = () => {

    const navigate = useNavigate();
    const [msgDlgShow, setMsgDlgShow] = useState(false);
    const [msgAlrtUsr, setMsgAlrtUsr] = useState(false);
    const [msgAlrtPwd, setMsgAlrtPwd] = useState(false);
    const [msgAlrtDom, setMsgAlrtDom] = useState(false);
    let [permisoSupervisor, setPermisoSupervisor] = useState(false);     
    let [permisoAjuste, setPermisoAjuste] = useState(false);         
    let [permisoElimina, setPermisoElimina] = useState(false);           
    const [sHCarga, setSHCarga] = useState(false);    
    const dispatch = useDispatch();
    let [formData, setFormData] = useState({idUser:"", password:"", dominio: "ROSENCHILE"});

    let pflsw = 0;    

    const changeText = (evnt: any) => {

        formData = {...formData, [evnt.target.id]: evnt.target.value.trim()}
        setFormData(formData);
        setMsgAlrtUsr(false);
        setMsgAlrtPwd(false);
        setMsgAlrtDom(false);       
    }

    const keyPress = (evnt: any) => {
        if (evnt.key === "Enter"){
            sendForm(evnt);
        }      
    }    

    const getPerfil = async() =>{
                                                         
        const recurso = `${env.REACT_APP_API_URL_SECURITY}/apiSeguridad20/Api/Perfiles?dominio=${formData.dominio}&usuario=${formData.idUser}`; 
        const response = await httpApiGetText(recurso, {
            'Accept': 'application/json',
            'x-api-key': `${env.REACT_APP_X_API_KEY}`,
            'Content-Type': 'application/json'
        });
        const  dt = await JSON.parse(response.message);      
        let pfl: string[] = [];
        dt.linperfilescab.map((obj: any)=>{
            pfl.push(obj.id_perfil);
        });  
        
        return pfl;
    }

    const getTokensPerfil = async(perfil: string) =>{
                                                         
        const recurso = `${env.REACT_APP_API_URL_SECURITY}/apiSeguridad20/Api/Tokens?dominio=${formData.dominio}&usuario=${formData.idUser}&perfil=${perfil}`; 
        const response = await httpApiGetText(recurso, {
            'Accept': 'application/json',
            'x-api-key': `${env.REACT_APP_X_API_KEY}`,
            'Content-Type': 'application/json'
        });
        const  dt = await JSON.parse(response.message);        
        // proceso que verifica si los tokens de eliminar archivos y subir los tiene el perfíl de usuario 
        dt.linTokens.map((obj: any, idx: number)=>{
            pflsw = (obj.id_token === env.REACT_APP_INV_ELIMINAR_ARCHVOS) ? (idx + 1): pflsw;
            pflsw = (obj.id_token === env.REACT_APP_INV_SUBIR_ARCHIVOS) ? ((idx + 1) + pflsw): pflsw;
        });  
        
        return pflsw;
    }    

    const init = async ()=>{
            
        let permiso: any = await ConsultaPermisos(formData.dominio, formData.idUser, "456a2ad6-3335-489c-b2a0-0228525f3d83", 
        {
            'Content-Type': 'application/json',
            "x-api-key": `${env.REACT_APP_X_API_KEY}`
        });
        let leng = await JSON.parse(permiso.message).linTokens.length
        permisoSupervisor = (leng > 0);       
        setPermisoSupervisor(permisoSupervisor);  

        permiso = await ConsultaPermisos(formData.dominio, formData.idUser, "5e88fd77-9e74-4761-afee-bff9fd602917", 
        {
            'Content-Type': 'application/json',
            "x-api-key": `${env.REACT_APP_X_API_KEY}`
        });
        leng = await JSON.parse(permiso.message).linTokens.length       
        permisoAjuste = (leng > 0);           
        setPermisoAjuste(permisoAjuste);  
  
        permiso = await  ConsultaPermisos(formData.dominio, formData.idUser, "2a750c15-4b08-4287-8c19-54c0f5089aa5", 
        {
            'Content-Type': 'application/json',
            "x-api-key": `${env.REACT_APP_X_API_KEY}`
        });
        leng = await JSON.parse(permiso.message).linTokens.length     
        permisoElimina = (leng > 0);              
        setPermisoElimina(permisoElimina);        
   
    }    

    const sendForm = async(e: any) =>{
        e.preventDefault();

        let sw = 0;
        
        (formData.idUser === "") ? setMsgAlrtUsr(true) : sw++; 
        (formData.password === "") ? setMsgAlrtPwd(true) : sw++; 
        (formData.dominio === "") ? setMsgAlrtDom(true): sw++;

        if (sw === 3){

            setSHCarga(true);

            const recurso = `${env.REACT_APP_API_URL_SECURITY}/apiSeguridad20/Api/Usuarios?dominio=${formData.dominio}&usuario=${formData.idUser}&password=${formData.password}`; 
            const response = await httpApiGetText(recurso, {
                'Accept': 'application/json',
                'Authorization': `API_Key ROSENPROD_KEY=${env.REACT_APP_ROSENPROD_KEY}`,
                'Rosen-API-Key': `${env.REACT_APP_Rosen_API_Key}`,
                'Rosen-API-Secret': `${env.REACT_APP_Rosen_API_Secret}`,
                'x-api-key': `${env.REACT_APP_X_API_KEY}`,
                'Content-Type': 'application/json'
            });
            const  dt = JSON.parse(response.message);

            if (dt.codError !== 0) {
                setMsgDlgShow(true);
            }else{
                const perfil = await getPerfil();
                const tokens = await getTokensPerfil("INV_CTRL_DOCS");

                // Se seleccionan los tokens de las opciones a las que puede acceder
                const TokensArrOpcionesMenu: string[] = []; 
                dt.lintokens.map((tk: any)=>{
                    switch (tk.id_token) {
                        case env.REACT_APP_DASHBOARD: 
                        case env.REACT_APP_ETIQUETA:  
                        case env.REACT_APP_GESTIONINVENTARIO:
                        case env.REACT_APP_TRATAMIENTOETIQUETAS :
                        case env.REACT_APP_DOCUMENTOS :                            
                        case env.REACT_APP_AVANCEINVENTARIO :
                        case env.REACT_APP_REPORTEINVENTARIO :
                        case env.REACT_APP_INFORMESALDOS :
                        case env.REACT_APP_DESCARGAMASIVA :
                        case env.REACT_APP_APERTURARECONTEO :
                        case env.REACT_APP_AVANCEYCIERRERECONTEO :
                        case env.REACT_APP_PERIODOMUESTRA :
                        case env.REACT_APP_AVANCEYCIERREMUESTRAS :
                        case env.REACT_APP_CLASIFICACIONDIFERENCIAS :
                        case env.REACT_APP_KARDEXINICIAL :
                        case env.REACT_APP_KARDEXFINAL :
                        case env.REACT_APP_ROLLFORWRAD :
                        case env.REACT_APP_DETALLEAJUSTES: 
                        case env.REACT_APP_GESTIONMUESTRAS: 
                        case env.REACT_APP_DESCARGAMASIVAMUESTRAS: 
                        case env.REACT_APP_SOLICITUDMERMAS: 
                        case env.REACT_APP_EDITARMERMAS:
                        case env.REACT_APP_GESTIONMERMAS: {
                            TokensArrOpcionesMenu.push(tk.id_token);  
                        }
                    }
                });   
                // trae los codigos-descripción de los centros
                const centro = await getCentros(formData.dominio, formData.idUser, "centros", "inventario_corp");
                let centros: string[] = [];
                centro.message.listavalores_asignados.map((obj: any)=>{
                    centros.push(`${obj.descripcion}`);
                }); 

                // trae los codigos-descripción de los almecenes
                const almacen = await getCentros(formData.dominio, formData.idUser, "almacenes", "inventario_corp");
                let almacenes: string[] = [];
                almacen.message.listavalores_asignados.map((obj: any)=>{
                    almacenes.push(`${obj.clave.split(";")[0]} - ${obj.descripcion}`);
                }); 

                await init();

                sessionStorage.setItem("entorno", 
                JSON.stringify({pais:formData.dominio, user:formData.idUser, centros:centros, almacenes:almacenes, tokens:TokensArrOpcionesMenu, 
                    periodoDefault:"", centroDefault:"", almacenDefault: "", perfil: perfil, permisoSupervisor: permisoSupervisor, permisoAjuste: permisoAjuste, permisoElimina: permisoElimina, 
                    permisoBorrarDocumentos: ((tokens === 1) || (tokens === 3)), permisoSubirDocumentos:((tokens === 2) || (tokens === 3))}));
                    
                dispatch(SetEntornoEmp({pais:formData.dominio, user:formData.idUser, centros:centros, almacenes:almacenes, tokens:TokensArrOpcionesMenu,
                    periodoDefault:"", centroDefault:"", almacenDefault: "", perfil: perfil, permisoSupervisor: permisoSupervisor, permisoAjuste: permisoAjuste, permisoElimina: permisoElimina,
                    permisoBorrarDocumentos: ((tokens === 1) || (tokens === 3)), permisoSubirDocumentos:((tokens === 2) || (tokens === 3))})); 

                // Proceso que verifica si tiene acceso autorizado a dashboard. De lo contrario redirecciona a landingpage
                if ((TokensArrOpcionesMenu.filter((tkn: string)=> tkn === env.REACT_APP_DASHBOARD).length > 0) ? true: false){
                    navigate("/dashboard");                    
                }else{
                    navigate("/landingpage");                           
                }
            }   
            
            setSHCarga(false);            
        }
    }

    /* manejadores de msgDialog */
    const handlerOk = () => {
        setMsgDlgShow(false);
    }

    useEffect(()=>{

        const init = async () =>{
            const recurso = `${env.REACT_APP_API_URL_SECURITY}/apiSeguridad20/Api/Dominios`;             
            const data = await fncDominios(recurso);
            let aux = getDominios(data.message);
            aux = aux.splice(1, aux.length - 1);
        }

        init();
    }, []);

    return(
        <>
            <div className='container mt-5 shadow-lg p-3 mb-5 border rounded bg-light pageMobile'>
                <Container className=' container-sm rounded' >
                    <ReactSVG src='./logo-rosen3.svg' className=" d-block img-fluid mx-auto m-3"  style={{width: "250px", height:"100px"}}/>
                </Container>
                <Container>
                    <div className='m-3 text-center'><span className='h3'>Inicio de sesión</span></div>
                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label><FaUserTie /> Usuario</Form.Label>
                            <Form.Control type="user" placeholder="usuario" id="idUser" onChange={changeText} value={formData.idUser}/>
                            <Form.Text>
                                <Alert variant="danger" show={msgAlrtUsr} className="p-1 m-0">
                                    <FaRegHandPaper className='mb-1' /> Debe ingresar un usuario válido!!!
                                </Alert>
                                <div className=" text-end ">Ingrese con un usuario autorizado</div>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label> <FaKey /> Constraseña</Form.Label>
                            <Form.Control type="password" placeholder="contraseña"  id="password" onChange={changeText} onKeyUp={keyPress} value={formData.password}/>
                            <Form.Text>
                                <Alert variant="danger" show={msgAlrtPwd} className="p-1 m-0">
                                        <FaRegHandPaper className='mb-1' /> Debe ingresar una contraseña válida!!!
                                </Alert>
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label> <FaRegBuilding /> Dominio</Form.Label>
                            <Form.Select aria-label="Dominio"  id="dominio"  onChange={changeText}  defaultValue={"ROSENCHILE"}>
                                <option value="ROSENCHILE"  key={-1}>ROSENCHILE</option>
                                {/*{
                                   xmlTree !=null ? xmlTree.map((dominio: string, idx: number) => <option value={dominio} key={idx}>{dominio}</option>): null 
                                }*/}
                            </Form.Select>  
                            <Form.Text>
                                <Alert variant="danger" show={msgAlrtDom} className="p-1 m-0">
                                        <FaRegHandPaper className='mb-1' /> Debe seleccionar un dominio.
                                </Alert>
                            </Form.Text>                             
                        </Form.Group>  
                        <Form.Group className="mt-4 mb-4">
                            <Col className='text-center mt-3'>
                                <Button onClick={sendForm} className='w-75 border ' style={{backgroundColor: " #404E67", height:"60px"}}> <FaDoorOpen /> Ingresar</Button>
                            </Col>
                        </Form.Group>                                           
                    </Form>
                </Container>
            </div>
            {/*********** seccion de modales **********************/}
            {/*********** cuadro de dialogo para errores **********/}
            <MsgDialog
                Show={msgDlgShow}
                Title={`Inicio de sesión`}
                Icon="!"
                Message="Usuario y/o password incorrecto. Por favor verifiquelo y vuelva a intentar."
                BtnOkName="Aceptar"
                BtnNokName=""
                HandlerClickOk={handlerOk}
                HanlerdClickNok={null}
            />
            <MsgModalSpinner 
                Show={sHCarga}
                text="Un momento porfavor, validando acceso y cargando valores..."
                color="#FF7588"
            />
        </>
    )

};

export default PageLogin;